.App {
  text-align: center;
}

.col, .col-12 {
  margin: 10px 0px;
}

.header img {
  max-width: 100%;
}

.footer {
  color: #818181;
}

.footer img {
  max-width: 150px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
